<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div
				v-if="is_app && false"
				class="subindex_head"
			>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.common.notify }}</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<button>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.release_info }}</div>
										<div class="cell_action"><span class="text">bass</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				:class="is_app ? 'subindex_item' : 'subindex_head'"
			>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.common.notify_default }}</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="postNoticeAlram">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.notify_notice }}</div>
										<div class="cell_action">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span
													class="btn-toggle"
													:class="{ on: user.notice_note_fg == 'Y' }"
												><em class="hide">{{  $language.common.notify_notice }}</em></span>
											</p>
										</div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click=" postStakingAlram">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.nft.notify_staking_list }}</div>
										<div class="cell_action">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span
													class="btn-toggle"
													:class="{ on: user.stakg_note_fg == 'Y' }"
												><em class="hide">{{  $language.common.notify_notice }}</em></span>
											</p>
										</div>
									</div>
								</button>
							</li>
							<li
								class="settings_list_item"
								@click="$emit('to', { name: 'mafia102'})"
							>
								<button>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.notify_friend }}</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.notify.notify_cartel }}</span>
						<ul class="settings_list">
							<li
								class="settings_list_item"
								@click="$emit('to', { name: 'mafia107', params: { type: 'notice'}})"
							>
								<button>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.notify.notify_notice }}</div>
										<div class="cell_action"><span v-if="false" class="text">{{ count_notice }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.notify.message_02 }}</span>
						<ul class="settings_list">
							<li
								class="settings_list_item"
								@click="$emit('to', { name: 'mafia107', params: { type: 'comment'}})"
							>
								<button>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.notify.notify_comment }}</div>
										<div class="cell_action"><span v-if="false" class="text">{{ count_comment }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">{{  $language.voting.title_voting_alram }} <!-- 투표 알림 --></span>
						<ul class="settings_list">
							<li
								class="settings_list_item"
							>
								<button @click=" postVotingAlram">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.voting.txt_voting_alram_ing }} <!-- 신규, 참여한 보팅 종료 알림 --></div>
										<div class="cell_action">
											<p class="toggle-group">
												<span
													class="btn-toggle"
													:class="{ on: user.cartl_vote_note_fg == 'Y' }"
												></span>
											</p>
										</div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia105'
		, props: ['Axios', 'user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.notify
					, title: this.$language.common.notify
					, not_footer: true
					, type: 'mypage'
					, from: 'myPage'
				}
				,item: {
					is_staking: false
					,is_notice: false
				}
				, items_cartel: []
			}
		}
		, computed: {
			count_notice: function(){
				let t = 0

				for(let item of this.items_cartel){
					if(item.is_notice){
						t++
					}
				}

				return t
			}
			, count_comment: function(){
				let t = 0

				for(let item of this.items_cartel){
					if(item.is_comment){
						t++
					}
				}

				return t
			}
			, is_app: function(){
				let t = false
				try{
					this.$common.inAppWebviewCommunity('android', 'is_app')
					t = true
				}catch (e){
					t = false
				}
				return t
			}
		}
		,methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: ''
						, data: {

						}
					})
					if(result.success){
						this.item = result.data
					}else{
						throw 'not info'
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
					if(process.env.VUE_APP_TYPE == 'sample'){
						let result = this.$sample.getMyinfo()

						if(result.success){
							this.item = result.data
							console.log(this.item)
						}else{
							this.$bus.$emit('notify',  { type: result.success, message: result.message })
						}
					}
				}
			}
			, postStakingAlram: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_staking_alram
						, data: {
							member_number: this.user.member_number
							, stakg_note_fg: this.user.stakg_note_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})
					if(result.success){
						this.user.stakg_note_fg = this.user.stakg_note_fg == 'Y' ? 'N' : 'Y'
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postNoticeAlram: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice_alram
						, data: {
							member_number: this.user.member_number
							, notice_note_fg: this.user.notice_note_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})
					if(result.success){
						this.user.notice_note_fg = this.user.notice_note_fg == 'Y' ? 'N' : 'Y'
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getMyCartel: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_cartel_list
						, data: {
							type: 'my_cartel'
						}
					})

					if(result.success){
						this.items_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, postVotingAlram: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_voting_alram
						, data: {
							member_number: this.user.member_number
							, cartl_vote_note_fg: this.user.cartl_vote_note_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})
					if(result.success){
						this.user.cartl_vote_note_fg = this.user.cartl_vote_note_fg == 'Y' ? 'N' : 'Y'
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			//this.getData()
			//this.getMyCartel()
		}
	}
</script>